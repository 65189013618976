import { ContainerModuleLoader, withDependencies } from '@wix/thunderbolt-ioc'

import { PopupsSymbol, PopupsLinkUtilsAPISymbol, name, PopupUtilsSymbol, PopupsAPISym } from './symbols'
import { DsStructureAPISym, LifeCycle, SiteLinkClickHandlerSymbol, StructureAPI } from '@wix/thunderbolt-symbols'
import { popupsAPI } from './popupsAPI'
import { Popups } from './popups'
import { PopupsPageNavigationHandler } from './popupsPageNavigationHandler'
import { PopupLink } from './popupLink'
import { PopupsCloseBehaviors } from './popupsCloseBehaviors'
import { PopupsLinkUtilsAPI } from './popupsLinkUtilsAPI'
import { PopupUtils } from './popupUtils'

export const site: ContainerModuleLoader = (bind) => {
	bind(PopupsSymbol).to(Popups)
	bind(PopupUtilsSymbol).to(PopupUtils)
	bind(PopupsLinkUtilsAPISymbol).to(PopupsLinkUtilsAPI)
	bind(SiteLinkClickHandlerSymbol).to(PopupLink)
	bind(PopupsAPISym).to(withDependencies([StructureAPI], popupsAPI))
	bind(LifeCycle.AppWillLoadPageHandler).to(PopupsPageNavigationHandler)
}

export const page: ContainerModuleLoader = (bind) => {
	bind(LifeCycle.PageWillMountHandler).to(PopupsCloseBehaviors)
}

export const editor: ContainerModuleLoader = (bind) => {
	bind(PopupsAPISym).to(withDependencies([DsStructureAPISym], popupsAPI))
}

export * from './types'
export { name, PopupsSymbol, PopupsAPISym, PopupsLinkUtilsAPISymbol, PopupUtilsSymbol }
